@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .pars-box {
    @apply rounded-lg border-2 bg-white p-4;
  }

  .dark .pars-box {
    @apply border-gray-600 bg-gray-950;
  }

  .pars-h1 {
    @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl;
  }

  .pars-h2 {
    @apply scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0;
  }

  .pars-h3 {
    @apply scroll-m-20 text-2xl font-semibold tracking-tight;
  }

  .pars-h4 {
    @apply scroll-m-20 text-xl font-semibold tracking-tight;
  }
}

/* Userback styling */
.userback-button {
  margin-top: 10em !important;
}

@media (max-width: 640px) {
  .userback-button {
    top: 10% !important;
  }
}

:root {
  /* New DOE Brand Colors 2025 */
  --highcharts-color-0: #0F142E; /* Midnight Blue - Primary */
  --highcharts-color-1: #21409A; /* Clear Blue - Secondary */
  --highcharts-color-2: #085A9B; /* Energy Blue */
  --highcharts-color-3: #61AD00; /* Energy Green */
  --highcharts-color-4: #FA9441; /* Energy Orange */
  --highcharts-color-5: #FFBE2E; /* Energy Yellow */
  --highcharts-color-6: #106636; /* Deep Green */
  --highcharts-color-7: #8A181A; /* Cardinal Red */
  --highcharts-color-8: #5E4F4D; /* Soil Brown */
  --highcharts-color-9: #52565C; /* Dark Cool Gray */

  /* Primary colors for key chart elements */
  --highcharts-color-primary: var(--highcharts-color-0);
  --highcharts-color-secondary: var(--highcharts-color-1);
  --highcharts-color-tertiary: var(--highcharts-color-2);

  /* Series colors - maintaining the same structure */
  --highcharts-series-0-color: var(--highcharts-color-0);
  --highcharts-series-1-color: var(--highcharts-color-1);
  --highcharts-series-2-color: var(--highcharts-color-2);
  --highcharts-series-3-color: var(--highcharts-color-3);
  --highcharts-series-4-color: var(--highcharts-color-4);
  --highcharts-series-5-color: var(--highcharts-color-5);
  --highcharts-series-6-color: var(--highcharts-color-6);
  --highcharts-series-7-color: var(--highcharts-color-7);
  --highcharts-series-8-color: var(--highcharts-color-8);
  --highcharts-series-9-color: var(--highcharts-color-9);
}

.highcharts-partfill-overlay {
  filter: brightness(0.95);
  mix-blend-mode: multiply;
}